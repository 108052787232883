import { useEffect } from "react";

import getData from "utils/getData";

import useStore, { UserSelection } from "utils/useStore";

const CREMATION_ID = 313;

const useExtraTransportation = (burial: UserSelection) => {
  const agency = useStore((state) => state.agency);
  const hospital = useStore((state) => state.hospital);
  const shadowHospital = useStore((state) => state.shadowHospital);
  const blessingPlace = useStore((state) => state.blessingPlace);
  const skipBlessingPlace = useStore((state) => state.skipBlessingPlace);
  const targetMunicipality = useStore((state) => state.targetMunicipality);
  const setUserSelection = useStore((state) => state.setUserSelection);

  const hospitalForCalculation = hospital || shadowHospital;

  useEffect(() => {
    if (
      !hospitalForCalculation ||
      !blessingPlace ||
      !agency ||
      !burial ||
      !targetMunicipality
    ) {
      return;
    }

    const params = {
      agency: String(agency),
      hospital: String(hospitalForCalculation.id),
      blessing_place: String(blessingPlace.id),
      include_extra: burial.id === CREMATION_ID ? "1" : "0",
    } as Record<string, string>;

    if (skipBlessingPlace) {
      params["blessing_place_municipality"] = String(targetMunicipality.id);
    }

    const paramsStr = new URLSearchParams(params).toString();

    getData(
      (result: any) => {
        setUserSelection("transportationCost", parseInt(result.cost.total.replace(/,/g, '')));
      },
      `transportation/?${paramsStr}`,
      "memoria",
      "v1"
    );
  }, [
    hospitalForCalculation,
    blessingPlace,
    agency,
    setUserSelection,
    burial,
    targetMunicipality,
    skipBlessingPlace,
  ]);
};

export default useExtraTransportation;
